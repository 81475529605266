import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

const BASE_URL = `${process.env.NEXT_PUBLIC_API_URL}`;

const apiClient = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

// 에러 처리 함수
export const handleApiError = (error: AxiosError) => {
  if (error.response) {
    const { status, data } = error.response as AxiosResponse;
    const isNotFoundCookie = data.message === "NotFoundCookie";
    const errorMessage = data.message || "오류가 발생했습니다";

    switch (status) {
      case 400:
        console.error("Bad Request:", data);
        throw new Error(errorMessage);
      case 401:
        console.error("Unauthorized:", data);
        throw new Error(errorMessage);
      case 403:
        console.error("Forbidden:", data);
        throw new Error(errorMessage);
      case 404:
        if (isNotFoundCookie) {
          console.warn("Cookie not found");
        } else {
          console.error("Not Found:", data);
        }
        break;
      case 500:
        console.error("Server Error:", data);
        throw data;
      default:
        console.error(`Unhandled error status: ${status}`, data);
        throw new Error(errorMessage);
    }
  } else if (error.request) {
    console.error("No response received:", error.request);
    throw new Error("서버로부터 응답을 받지 못했습니다");
  } else {
    console.error("Error setting up request:", error.message);
    throw new Error("요청 설정 중 오류가 발생했습니다");
  }
};

apiClient.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => handleApiError(error),
);

export const apiInstance = apiClient;
