import { CacheConfiguration, Duration, TimeOfDay } from "./types";

export const DEFAULT_REFRESH_TIME = {
  type: "timeOfDay" as const,
  value: {
    hour: 9,
    minute: 0,
  },
};

export const isValidCache = (
  timestamp: number,
  refreshTime: CacheConfiguration["refreshTime"] = DEFAULT_REFRESH_TIME,
) => {
  const now = new Date();
  const cacheDate = new Date(timestamp);

  if (refreshTime?.type === "duration") {
    const duration = refreshTime.value as Duration;
    const refreshMilliseconds =
      (duration.hours ?? 0) * 60 * 60 * 1000 +
      (duration.minutes ?? 0) * 60 * 1000;

    return now.getTime() - timestamp < refreshMilliseconds;
  }

  // timeOfDay 타입
  const timeOfDay = refreshTime?.value as TimeOfDay;
  const todayRefreshDate = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    timeOfDay.hour,
    timeOfDay.minute ?? 0,
  );

  if (now < todayRefreshDate) {
    return true;
  }

  return cacheDate > todayRefreshDate;
};
