import { createQueryString } from "_utils/date";
import dayjs from "dayjs";

import { useApi } from "../swr-instance";

const getDate = {
  previous: {
    startDate: dayjs()
      .subtract(1, "month")
      .startOf("month")
      .format("YYYY-MM-DD"),
    endDate: dayjs().subtract(1, "month").endOf("month").format("YYYY-MM-DD"),
  },
  current: {
    startDate: dayjs().startOf("M").format("YYYY-MM-DD"),
    endDate: dayjs().endOf("M").format("YYYY-MM-DD"),
  },
};

export function useEC2InstancesInfo() {
  return useApi({
    apiKey: "MAIN",
    path: "/monitoring/instances",
  });
}

export function useEC2InstanceMetrics() {
  return useApi({
    apiKey: "MAIN",
    path: "/monitoring/metrics",
  });
}

export function useDescribeAlarms() {
  return useApi({
    apiKey: "MAIN",
    path: "/monitoring/describe-alarms",
  });
}

export function getCostAndUsage(
  startDate: string,
  endDate: string,
  userId: string,
) {
  return useApi({
    apiKey: "MAIN",
    path: `/monitoring/cost?startDate=${startDate}&endDate=${endDate}`,
    cacheConfigs: {
      cache: true,
      cacheKey: userId,
    },
  });
}

export function getForecastCost(dateType = "current", userId: string) {
  const queryParams = getDate[dateType];
  const query = createQueryString(queryParams);

  return useApi({
    apiKey: "MAIN",
    path: `/monitoring/forecast-cost/?${query}`,
    cacheConfigs: {
      cache: true,
      cacheKey: userId,
    },
  });
}
