import { useTranslation } from "react-i18next";

/**
 * ISO 8601 형식의 날짜 문자열을 "M/D/YYYY, h:MM AM/PM" 형식으로 변환합니다.
 * @param isoString ISO 8601 형식의 날짜 문자열 (예: "2024-08-25T17:39:12.068Z")
 * @returns 변환된 날짜 문자열 (예: "8/25/2024, 5:39 PM")
 * @example
 * const formattedDate = formatDate("2024-08-25T17:39:12.068Z");
 *console.log(formattedDate); // 출력: 8/25/2024, 5:39PM
 */
export function formatISOToReadableDate(isoString: string): string {
  const date = new Date(isoString);

  // 날짜가 유효하지 않으면 빈 문자열 반환
  if (isNaN(date.getTime())) {
    return "";
  }

  const month = date.getMonth() + 1; // getMonth()는 0부터 시작하므로 1을 더함
  const day = date.getDate();
  const year = date.getFullYear();

  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";

  // 12시간 형식으로 변환
  hours = hours % 12;
  hours = hours ? hours : 12; // 0시는 12로 표시

  return `${month}/${day}/${year}, ${hours}:${minutes}${ampm}`;
}

// 사용 예시
// const formattedDate = formatDate("2024-08-25T17:39:12.068Z");
// console.log(formattedDate); // 출력: 8/25/2024, 5:39PM

/**
 * ISO 8601 형식의 날짜 문자열을 "M/D/YYYY, h:MM AM/PM" 형식으로 변환합니다.
 * @param isoString ISO 8601 형식의 날짜 문자열 (예: "2024-08-25T17:39:12.068Z")
 * @returns 변환된 날짜 문자열 (예: "2024.9.10")
 * @example
 * const formattedDate = formatDate("2024-08-25T17:39:12.068Z");
 */

export function formattedDotDate(isoString: string): string {
  const date = new Date(isoString);

  if (isNaN(date.getTime())) {
    return "";
  }

  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();

  let hours = date.getHours();

  // 12시간 형식으로 변환
  hours = hours % 12;
  hours = hours ? hours : 12;

  return `${year}.${month}.${day}`;
}

/**
 *
 * @param params
 * @description 객체형태를 쿼리 문자열로 변경하는 함수임
 * @returns
 */
export function createQueryString(params) {
  return Object.keys(params)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`,
    )
    .join("&");
}

export const formatDate = (date: string, language: string) => {
  const [year, month, day] = date.split("T")[0].split("-");

  // 월을 영어로 변환하기 위한 배열
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  if (language === "ko") {
    const yearText = `${year}년`;
    const monthText = month ? `${parseInt(month)}월` : "";
    const dayText = day ? `${parseInt(day)}일` : "";
    return `${yearText} ${monthText} ${dayText}`; // 한국어 포맷
  } else {
    const monthName = month ? monthNames[parseInt(month) - 1] : ""; // 월을 영어로 가져옴
    const dayText = day ? `${parseInt(day)},` : "";
    return `${monthName} ${dayText} ${year}`; // 영어 포맷
  }
};
