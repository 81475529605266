import { apiInstance } from "_utils/axiosInstance";
import { AxiosError, AxiosRequestConfig } from "axios";
import useSWR from "swr";

import { API_CONFIG, ApiConfigKey } from "./api-config";
import { isValidCache } from "./cashe-utils";
import { ApiParams, CacheConfiguration } from "./types";

const fetcher = async <K extends ApiConfigKey>(
  apiKey: K,
  path: string,
  params?: Record<string, any>,
  cacheConfigs: CacheConfiguration = {},
): Promise<any> => {
  const { cache = false, cacheKey: key, refreshTime }: any = cacheConfigs;

  const url = `${path}`;
  const config: AxiosRequestConfig = { params };
  const baseUrl = API_CONFIG[apiKey];

  if (!baseUrl) {
    throw new Error(`API URL for ${apiKey} is not defined`);
  }

  const cacheKey = `${key}-${path}`;

  if (cache) {
    const cache = await caches.open("api-cache");
    const cachedResponse = await cache.match(cacheKey);

    if (cachedResponse) {
      const cachedData = await cachedResponse.json();

      // 캐시 유효성 검사
      if (isValidCache(cachedData.timestamp, refreshTime)) {
        return cachedData.data;
      } else {
        await cache.delete(cacheKey);
      }
    }

    const { data } = await apiInstance.get(url, config);

    const responseToCache = new Response(
      JSON.stringify({
        data,
        timestamp: new Date().getTime(),
      }),
      { headers: { "Content-Type": "application/json" } },
    );

    await cache.put(cacheKey, responseToCache);
    return data;
  }

  const { data } = await apiInstance.get(url, config);
  return data;
};

/**
 *
 * @example
 * 선언부에서 아래와 같이 사용할 수 있습니다.
 *
// 특정 시간 대 11:00에 리프레시
export function getCostAndUsage(
  startDate: string,
  endDate: string,
  userId: string,
) {
  return useApi({
    apiKey: "MAIN",
    path: `/monitoring/cost?startDate=${startDate}&endDate=${endDate}`,
    cacheConfigs: {
      cache: true,
      cacheKey: userId,
      refreshTime: {
        type: "timeOfDay",
        value: { hour: 11 },
      },
    },
  });
}
// 지속 시간 3시간 동안 유효
export function getCostAndUsage(
  startDate: string,
  endDate: string,
  userId: string,
) {
  return useApi({
    apiKey: "MAIN",
    path: `/monitoring/cost?startDate=${startDate}&endDate=${endDate}`,
    cacheConfigs: {
      cache: true,
      cacheKey: userId,
      refreshTime: {
        type: "duration",
        value: { hours: 3 },
      },
    },
  });
}
 */
export function useApi({
  apiKey,
  path,
  params,
  swrConfigs = {},
  cacheConfigs = {}, // 기본값 {}
}: ApiParams) {
  const { cache = false, cacheKey } = cacheConfigs;
  const { data, error, isLoading, mutate } = useSWR<AxiosError>(
    params ? [apiKey, path, params] : [apiKey, path],
    ([apiKey, path, params]) =>
      fetcher(apiKey, path, params, { cache, cacheKey }),
    swrConfigs,
  );

  return {
    data,
    isLoading,
    isError: error,
    mutate,
  };
}
